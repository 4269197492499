// @flow strict

import React, { useEffect, useState, type Node } from "react";
import { css, StyleSheet } from "aphrodite";
import { useParams, useNavigate } from "react-router-dom";
import ImageGallery from "react-image-gallery";
import {
  IconChevronRight,
  IconChevronLeft,
  IconExternalLink,
} from "@tabler/icons-react";
import { motion } from "framer-motion";
import Loader from "src/components/Loader.jsx";

import { useAppContext, type Project } from "src/AppContext";

const RightNavButton = ({ onClick, disabled }): Node => {
  return (
    <button
      className="image-gallery-icon image-gallery-right-nav"
      disabled={disabled}
      onClick={onClick}
    >
      <IconChevronRight />
    </button>
  );
};

const LeftNavButton = ({ onClick, disabled }): Node => {
  return (
    <button
      className="image-gallery-icon image-gallery-left-nav"
      disabled={disabled}
      onClick={onClick}
    >
      <IconChevronLeft />
    </button>
  );
};

const VisitLink = ({ url }: { +url: string }): Node => {
  const host = url.replace(/(?:https?:\/\/)?(?:www\.)?/i, "").split(".")[0];
  const formattedHost = host.charAt(0).toUpperCase() + host.slice(1);

  return (
    <div
      className={css([styles.link])}
      onClick={() => window.open(url, "_blank")}
    >
      <IconExternalLink size={16} />
      <div style={{ marginLeft: 5 }}>{formattedHost}</div>
    </div>
  );
};

const pageVariants = {
  hidden: {
    opacity: 0,
    y: 30,
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.25,
    },
  },
  breadcrumbHidden: {
    opacity: 0,
  },
  breadcrumbVisible: {
    opacity: 1,
    transition: {
      duration: 0.5,
    },
  },
};

const ProjectPage = (): Node => {
  const {
    state: { projects },
  } = useAppContext();

  const navigate = useNavigate();
  const { projectId } = useParams();
  const [project, setProject] = useState<?Project>(null);
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(projects.length === 0);
  const [showPage, setShowPage] = useState(!loading);

  useEffect(() => {
    if (projects.length > 0) {
      setLoading(false);
      const project = projects.find((p) => p.id === projectId);
      if (!project) {
        navigate("/404");
      } else {
        setProject(project);
      }
    }
  }, [projects]);

  useEffect(() => {
    if (project) {
      const images = project.images.map((image) => ({
        original: image,
        thumbnail: image,
      }));
      setImages(images);
    }
  }, [project]);

  if (!showPage) {
    return <Loader isLoading={loading} onComplete={() => setShowPage(true)} />;
  }

  return (
    <div className={css(styles.container)}>
      <motion.div
        initial="breadcrumbHidden"
        animate="breadcrumbVisible"
        variants={pageVariants}
      >
        <div className={css(styles.breadcrumbs)}>
          <div
            onClick={() => navigate("/portfolio")}
            className={css([styles.breadcrumb, styles.clickable])}
          >
            Portfolio
          </div>
          <div className={css(styles.breadcrumb)}>/</div>
          <div className={css([styles.breadcrumb, styles.active])}>
            {project?.title}
          </div>
        </div>
      </motion.div>
      <motion.div initial="hidden" animate="visible" variants={pageVariants}>
        <div style={{ width: "100%" }}>
          <ImageGallery
            items={images}
            showFullscreenButton={false}
            showPlayButton={false}
            renderRightNav={(onClick, disabled) => (
              <RightNavButton onClick={onClick} disabled={disabled} />
            )}
            renderLeftNav={(onClick, disabled) => (
              <LeftNavButton onClick={onClick} disabled={disabled} />
            )}
          />
        </div>

        <div style={{ marginTop: 15 }}>
          <div className={css(styles.titleContainer)}>
            <div className={css(styles.title)}>{project?.title}</div>
            {project?.links.length > 0 && <VisitLink url={project?.links[0]} />}
          </div>
          <div className={css(styles.divider)} />
          <div className={css(styles.metadata)}>
            {project?.release
              ? new Date(project.release + "T00:00:00").toLocaleDateString(
                  "en-US",
                  {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  }
                )
              : project?.year}
            {project?.tags.length > 0 && " • "}
            {project?.tags.join(" • ")}
          </div>
          <div className={css(styles.divider)} />
          <div className={css(styles.description)}>{project?.description}</div>
        </div>
      </motion.div>
    </div>
  );
};

const styles = StyleSheet.create({
  container: {
    padding: 15,
  },
  breadcrumbs: {
    display: "flex",
    alignItems: "center",
    marginBottom: 15,
  },
  breadcrumb: {
    fontWeight: 500,
    fontSize: 13,
    color: "#555",
    marginRight: 5,
  },
  clickable: {
    cursor: "pointer",
    ":hover": {
      color: "#000",
    },
  },
  active: {
    color: "#000",
    fontWeight: 600,
  },
  title: {
    fontWeight: 700,
    fontSize: 24,
    color: "#000",
  },
  description: {
    fontWeight: 400,
    fontSize: 14,
    color: "#555",
    marginTop: 5,
  },
  metadata: {
    fontWeight: 400,
    fontSize: 13,
    color: "#555",
    fontStyle: "italic",
    marginTop: 5,
  },
  divider: {
    width: "100%",
    height: 1,
    backgroundColor: "#f7f7f7",
    marginTop: 10,
    marginBottom: 10,
  },
  titleContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  link: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontWeight: 500,
    fontSize: 13,
    color: "#000",
    textDecoration: "none",
    cursor: "pointer",
    backgroundColor: "#f7f7f7",
    padding: "7px 15px",
    borderRadius: 5,
    ":hover": {
      color: "#000",
      backgroundColor: "#f2f2f2",
    },
  },
});

export default ProjectPage;

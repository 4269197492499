// @flow strict

import React, { type Node } from "react";
import { NavLink } from "react-router-dom";

const LINKS = [{ title: "Portfolio", href: "/portfolio" }];

type Props = {
  +onClick?: () => void,
};

const Navbar = ({ onClick }: Props): Node => {
  return (
    <div className="navLinkContainer">
      {LINKS.map((link) => {
        if (link.href.startsWith("/")) {
          return (
            <NavLink
              key={link.title}
              onClick={onClick}
              to={link.href}
              className={({ isActive, isPending }) =>
                `navLink ${isActive && `active`}`
              }
            >
              {link.title}
            </NavLink>
          );
        }

        return (
          <a
            key={link.title}
            onClick={onClick}
            className="navLink"
            href={link.href}
            target="_blank"
            rel="noopener noreferrer"
          >
            {link.title}
          </a>
        );
      })}
    </div>
  );
};

export default Navbar;

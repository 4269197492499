// @flow strict

import React, {
  createContext,
  useContext,
  useReducer,
  useEffect,
  type Node,
} from "react";

export type Project = {
  +id: string,
  +title: string,
  +description: string,
  +logo: string,
  +images: $ReadOnlyArray<string>,
  +tags: $ReadOnlyArray<string>,
  +links: $ReadOnlyArray<{ +url: string, +title: string }>,
  +color: string,
  +year: string,
  +release?: string,
};

// State
type State = {
  +projects: $ReadOnlyArray<Project>,
};

const initialState: State = {
  projects: [],
};

// Actions
export const ACTION_LOAD = "LOAD";
type Action = { +type: typeof ACTION_LOAD, +projects: $ReadOnlyArray<Project> };

const appContext = createContext<void | {
  dispatch: (Action) => void,
  state: State,
}>();

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ACTION_LOAD:
      return {
        ...state,
        projects: action.projects,
      };
    default:
      return state;
  }
};

export const AppContextProvider = ({ children }: { children: Node }): Node => {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    fetch("/projects.json")
      .then((response) => response.json())
      .then((projects) => {
        dispatch({
          type: ACTION_LOAD,
          projects,
        });
      });
  }, []);

  return (
    <appContext.Provider value={{ state, dispatch }}>
      {children}
    </appContext.Provider>
  );
};

export const useAppContext = (): ({
  dispatch: (Action) => void,
  state: State,
  ...
}) => {
  const context = useContext(appContext);
  if (!context) {
    throw new Error("useAppContext must be used within a AppContextProvider");
  }

  return context;
};

// @flow strict

import React, { useState, type Node } from "react";
import { css, StyleSheet } from "aphrodite";
import { useNavigate } from "react-router-dom";
import Hamburger from "hamburger-react";

import Navbar from "src/components/Navbar";

const Header = (): Node => {
  const navigate = useNavigate();
  const [isOpen, setOpen] = useState(false);

  return (
    <>
      <div className={css(styles.header)}>
        <div className="headerContent">
          <div className="showMobile">
            <Hamburger toggled={isOpen} size={20} toggle={setOpen} />
          </div>
          <div className={css(styles.title)} onClick={() => navigate("/")}>
            Sazan Dauti
          </div>
          <div className="showDesktop">
            <Navbar onClick={null} />
          </div>
        </div>
      </div>
      {isOpen && (
        <div className="navOverlay">
          <Navbar onClick={() => setOpen(false)} />
        </div>
      )}
    </>
  );
};

const styles = StyleSheet.create({
  header: {
    height: 40,
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    display: "flex",
    justifyContent: "space-around",
    zIndex: 99,
  },
  title: {
    fontSize: 18,
    fontWeight: 600,
    cursor: "pointer",
  },
  content: {
    flex: 1,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "100%",
    maxWidth: 970,
    padding: 15,
    backgroundColor: "#fff",
  },
});

export default Header;

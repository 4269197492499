// @flow strict

import React, { useEffect, useState, type Node } from "react";
import { css, StyleSheet } from "aphrodite";
import { motion, AnimatePresence } from "framer-motion";

type Props = {
  +isLoading: boolean,
  +onComplete: () => void,
};

const Loader = ({ isLoading, onComplete }: Props): Node => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    let exitAnimationTimeout;
    let onCompleteTimeout;

    if (!isLoading) {
      // Start the exit animation
      exitAnimationTimeout = setTimeout(() => {
        setIsVisible(false);

        // Call onComplete after the exit animation
        onCompleteTimeout = setTimeout(() => {
          onComplete();
        }, 150); // Duration of exit animation
      }, 250); // Start exit animation after a delay
    }

    // Cleanup function
    return () => {
      clearTimeout(exitAnimationTimeout);
      clearTimeout(onCompleteTimeout);
    };
  }, [isLoading, onComplete]);

  const variants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1, transition: { duration: 0.25 } },
    exit: { opacity: 0, transition: { duration: 0.15 } },
  };

  return (
    <AnimatePresence>
      {isVisible && (
        <motion.div
          className={css(styles.container)}
          initial="hidden"
          animate="visible"
          exit="exit"
          variants={variants}
        >
          <div className="spinner" />
        </motion.div>
      )}
    </AnimatePresence>
  );
};

const styles = StyleSheet.create({
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100px",
    width: "100%",
  },
});

export default Loader;

// @flow strict

import React, { useState, useEffect, type Node } from "react";
import { css, StyleSheet } from "aphrodite";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";

import { useAppContext, type Project } from "src/AppContext";
import Loader from "src/components/Loader";

import "/node_modules/react-image-gallery/styles/css/image-gallery.css";

const variants = {
  hidden: { opacity: 0, y: 20 },
  visible: (i) => ({
    opacity: 1,
    y: 0,
    transition: {
      delay: i * 0.075, // Delay each item by 0.1s times its index
      duration: 0.5,
    },
  }),
};

const ProjectCell = ({
  project,
  navigate,
  index,
}: {
  +project: Project,
  +navigate: (page: string) => void,
  +index: number,
}): Node => {
  return (
    <motion.div
      className="col-12 md:col-6 lg:col-4"
      variants={variants}
      initial="hidden"
      animate="visible"
      custom={index} // Use index for custom variant
      onClick={() => navigate(`/project/${project.id}`)}
    >
      <div className={css(styles.containerBtn)}>
        <div
          className={css(styles.bg)}
          style={{ backgroundColor: project.color }}
        >
          {project.logo && (
            <motion.div
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className={css(styles.logoContainer)}
            >
              <img
                src={project.logo}
                alt={project.title}
                className={css(styles.logo)}
                style={{
                  borderRadius: project.tags.includes("iOS") ? 15 : 0,
                }}
              />
            </motion.div>
          )}
        </div>
        <div className={css(styles.title)}>{project.title}</div>
        <div className={css(styles.description)}>{project.description}</div>
      </div>
    </motion.div>
  );
};

const PortfolioPage = (): Node => {
  const {
    state: { projects },
  } = useAppContext();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(projects.length === 0);
  const [showPage, setShowPage] = useState(!loading);

  useEffect(() => {
    if (projects.length > 0) {
      setLoading(false);
    }
  }, [projects]);

  if (!showPage) {
    return <Loader isLoading={loading} onComplete={() => setShowPage(true)} />;
  }

  return (
    <div>
      <div className="grid">
        {projects.map((project, idx) => (
          <ProjectCell
            key={project.id}
            project={project}
            navigate={navigate}
            index={idx}
          />
        ))}
      </div>
    </div>
  );
};

const styles = StyleSheet.create({
  bg: {
    width: "100%",
    height: 200,
    borderRadius: 5,
    overflow: "hidden",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  title: {
    fontWeight: 700,
    fontSize: 18,
    color: "#000",
    marginTop: 10,
  },
  description: {
    fontWeight: 400,
    fontSize: 13,
    color: "#555",
    marginTop: 5,
  },
  containerBtn: {
    padding: 15,
    borderRadius: 10,
    transition: "all 0.3s ease",
    cursor: "pointer",
    ":hover": {
      backgroundColor: "#f9f9f9",
    },
  },
  logoContainer: {
    width: "90%",
    height: "90%",
    borderRadius: 10,
    overflow: "hidden",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  logo: {
    height: "100%",
    objectFit: "contain",
  },
});

export default PortfolioPage;

// @flow strict

import React, { type Node } from "react";
import { css, StyleSheet } from "aphrodite";

const NotFoundPage = (): Node => {
  return (
    <div className={css(styles.content)}>
      <div className={css(styles.errorContainer)}>
        <div>404: Page Not Found</div>
      </div>
    </div>
  );
};

const styles = StyleSheet.create({
  content: {
    padding: 15,
    fontSize: 24,
    fontWeight: 600,
  },
  errorContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    backgroundColor: "#e74c3c",
    height: 150,
    borderRadius: 5,
    color: "#fff",
  },
});

export default NotFoundPage;

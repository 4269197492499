// @flow strict

import React, { type Node } from "react";
import { css, StyleSheet } from "aphrodite";
import {
  HashRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

import { AppContextProvider } from "src/AppContext";
import Header from "src/components/Header";
import PortfolioPage from "src/pages/PortfolioPage";
import ProjectPage from "src/pages/ProjectPage";
import NotFoundPage from "src/pages/NotFoundPage";

import "/node_modules/primeflex/primeflex.css";

const App = (): Node => {
  return (
    <AppContextProvider>
      <Router>
        <div className={css(styles.page)}>
          <Header />
          <div className={css(styles.center)}>
            <div className={css(styles.content)}>
              <Routes>
                <Route
                  path="/"
                  element={<Navigate to="/portfolio" replace />}
                />
                <Route path="/portfolio" element={<PortfolioPage />} />
                <Route path="/project/:projectId" element={<ProjectPage />} />
                <Route path="/404" element={<NotFoundPage />} />
                <Route path="*" element={<Navigate to="/404" replace />} />
              </Routes>
            </div>
          </div>
        </div>
      </Router>
    </AppContextProvider>
  );
};

const styles = StyleSheet.create({
  page: {
    display: "flex",
    flexDirection: "column",
    height: "100vh",
    width: "100vw",
  },
  center: {
    display: "flex",
    justifyContent: "space-around",
  },
  content: {
    width: "100%",
    maxWidth: 1000,
    marginTop: 75,
  },
});

export default App;
